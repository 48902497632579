import styled from "styled-components";
import React from "react";

export const Subtitle = styled(({ children, className }) => (
  <h2 className={className}>{children}</h2>
))`
  font-size: 12px !important;
  font-weight: 100 !important;
  font-style: italic;
  text-align: center;
  margin-top: 10px !important;
  margin-bottom: 34px !important;
`;
