import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import { Debug } from "../components/Debug";
import { ChildrenList } from "../components/shared/ChildrenList";
import { PageTitle } from "../components/shared/PageTitle";
import { Subtitle } from "../components/shared/Subtitle";
import { Icon } from "../components/shared/Icon";

const BackLink = styled(({ className, to }) => {
  return (
    <Link to={to} className={className}>
      <Icon type={"arrow-left"} />
      prev
    </Link>
  );
})`
  display: flex;
  align-items: center;
  color: #4a4a4a;
  margin-top: 20px;
`;

export const SetDetail = ({ node, helmet, parentNode, children }) => {
  let { title, subtitle } = node.frontmatter;

  if (!children) {
    console.warn(title, "no children");
  }

  let titles = [<PageTitle key="page">{title}</PageTitle>];
  if (subtitle) {
    titles.push(<Subtitle key="subtitle">{subtitle}</Subtitle>);
  }

  return (
    <div className="container content">
      {helmet || ""}
      <Debug>{{ setNode: node }}</Debug>
      {parentNode ? <BackLink to={parentNode.fields.slug} /> : null}
      {!parentNode ? <BackLink to={"/sets"} /> : null}

      <Debug>{{ parentNode }}</Debug>
      <Debug>{{ children }}</Debug>
      <ChildrenList
        title={titles}
        data={node.fields.childrenNodes}
        hideChildTitles={node.frontmatter.hideChildTitles}
      />
    </div>
  );
};

SetDetail.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  tag_line: PropTypes.string,
  helmet: PropTypes.object
};

const SetDetailPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <SetDetail
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Frank Masi">
            <title>{`${post.frontmatter.title}`}</title>
            <meta name="description" content={`${post.frontmatter.description}`} />
          </Helmet>
        }
        parentNode={post.fields.parentNode}
        node={post}
        children={post.fields.childrenNodes}
        childrenImage={post.fields.childrenImage}
      />
    </Layout>
  );
};

SetDetailPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default SetDetailPage;

export const pageQuery = graphql`
  query SetDetail($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html

      fields {
        childForImage {
          id
          frontmatter {
            entityType
            title
            image {
              publicURL
            }
          }
        }
        parentNode {
          id
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
        childrenNodes {
          id
          fields {
            slug
            childForImage {
              id
              frontmatter {
                image {
                  publicURL
                }
              }
            }
          }
          frontmatter {
            entityType
            title
            description
            subtitle
            image {
              publicURL
            }
            href
          }
        }
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        subtitle
        hideChildTitles
      }
    }
  }
`;
