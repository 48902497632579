import styled from "styled-components";
export const Overlay = styled.div``;

export const OverlayTitle = styled.div`
  text-align: center;
  font-size: 14px;
  max-width: 120px;
`;
OverlayTitle.defaultProps = {
  className: "has-text-weight-light",
};

export const HoverTile = styled.article`
  &:hover {
    ${Overlay} {
      opacity: 1;
    }
  }

  ${Overlay} {
    opacity: 0;
    background: rgba(74, 74, 74, 0.5490196078431373);
    color: #fff;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
`;
