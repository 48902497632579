import React from "react";
import styled from "styled-components";
import classnames from "classnames";

import { Overlay, OverlayTitle, HoverTile } from "./HoverTile";
import { PageTitle } from "./PageTitle";
import { Subtitle } from "./Subtitle";
import { Link } from "./Link";

import { Image, Figure, thumbnailSmall } from "./Image";
import { Debug } from "../Debug";

const Tile = styled(Link)`
  position: relative;
  background: #f1f1f1;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  min-height: -webkit-min-content;
  min-height: -moz-min-content;
  min-height: min-content;
`;

Tile.defaultProps = {
  className: "is-child "
};

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 1024px) {
  }

  @media (max-width: 794px) {
    justify-content: center;
  }
`;

function getHoverText(frontmatter = {}) {
  let { subtitle, entityType, title } = frontmatter;
  if (entityType == "set") {
    subtitle = "";
  }
  let overlayText = subtitle || title;
  if (overlayText && overlayText.toLowerCase && overlayText.toLowerCase() == "_blank_") {
    overlayText = "";
  }
  return overlayText;
}

class ChildrenListRaw extends React.Component {
  render() {
    let { data: posts } = this.props;
    let classes = classnames(this.props.className);
    return (
      <>
        <div className={classes}>
          {this.props.title}
          <ListContainer>
            {posts &&
              posts.map(post => {
                let imgField;
                if (post.fields.childForImage) {
                  imgField = post.fields.childForImage.frontmatter.image;
                } else if (post.frontmatter.coverImage) {
                  imgField = post.frontmatter.coverImage.image;
                } else {
                  imgField = post.frontmatter.image;
                }

                let overlayText = getHoverText(post.frontmatter);
                let linkProps =
                  post.frontmatter.entityType == "blog-link" && !!post.frontmatter.href
                    ? {
                        to: post.frontmatter.href,
                        target: "_blank"
                      }
                    : {
                        to: post.fields.slug
                      };
                return (
                  <div className="is-parent item-wrapper" key={post.id}>
                    <Debug>{{ post }}</Debug>
                    <Tile {...linkProps}>
                      <HoverTile className="">
                        <div>
                          <Image field={imgField} transforms={thumbnailSmall} />
                        </div>
                        <Overlay className="is-overlay tile  has-centered-text">
                          <OverlayTitle>{overlayText}</OverlayTitle>
                        </Overlay>
                      </HoverTile>
                    </Tile>
                  </div>
                );
              })}
          </ListContainer>
        </div>
      </>
    );
  }
}

export const ChildrenList = styled(ChildrenListRaw)`
  ${Figure} img {
    max-height: 150px;
  }
  ${Tile} {
    height: 250px;
    width: 250px;
  }
  ${PageTitle}, ${Subtitle} {
    max-width: 780px;
  }


  ${PageTitle} + ${Subtitle} {
    margin-top: -20px !important;
  }

  .item-wrapper {
    padding-right: 10px;
    padding-bottom: 10px;
  }

  @media (max-width: 1024px) {
    ${Tile} {
      padding: 5%;
    }
    ${ListContainer},${PageTitle} {
      max-width: 780px;
      margin-left: auto;margin-right: auto;
    }
  }

 ${OverlayTitle} {
  display: ${p => (p.hideChildTitles ? "none" : "block")}
 }

  @media (min-width: 1134px) {
    max-width: 1061px;
  }

  @media (min-width: 1134px) {
    padding-right: 2%;
  }

  @media (max-width: 794px) {
    ${Tile} {
      width: 290px;
      height: 290px;
      margin: 0 auto;
    }
  }
`;
